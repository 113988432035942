import { debounce } from "@mui/material";
import { KeenSliderOptions } from "keen-slider";
import "keen-slider/keen-slider.min.css";
import { KeenSliderInstance, KeenSliderPlugin, TrackDetails, useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";

const WheelControls: KeenSliderPlugin = (slider) => {
  let touchTimeout: ReturnType<typeof setTimeout>
  let position: {
    x: number
    y: number
  }
  let wheelActive: boolean

  function dispatch(e: WheelEvent, name: string) {
    position.x -= e.deltaX
    position.y -= e.deltaY
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      })
    )
  }

  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY,
    }
    dispatch(e, "ksDragStart")
  }

  function wheel(e: WheelEvent) {
    dispatch(e, "ksDrag")
  }

  function wheelEnd(e: WheelEvent) {
    dispatch(e, "ksDragEnd")
  }

  function eventWheel(e: WheelEvent) {
    e.preventDefault()
    if (!wheelActive) {
      wheelStart(e)
      wheelActive = true
    }
    wheel(e)
    clearTimeout(touchTimeout)
    touchTimeout = setTimeout(() => {
      wheelActive = false
      wheelEnd(e)
    }, 50)
  }

  slider.on("created", () => {
    slider.container.addEventListener("wheel", eventWheel, {
      passive: false,
    })
  })

}

interface CarouselProps {
  children: any,
  loop?: boolean,
  itemsPerSlide?: number | "auto" | (() => number | "auto"),
  itemsSpacing?: number,
  mode?: "snap" | "free"
}


function Arrow(props: {
    disabled: boolean
    left?: boolean
  }) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }

const CustomCarousel = (props: CarouselProps) => {
  const [loaded, setLoaded] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtFinish, setIsAtFinish] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider, setSlider] = useState<KeenSliderInstance|null>(null);
  const [options, setOptions] = useState<KeenSliderOptions|undefined>();
  const [sliderRef, instanceRef] = useKeenSlider(options, [WheelControls])

  useEffect(() => {
    setTimeout(() => {
      setOptions({
        loop: props.loop ? false : props.loop,
        rubberband: false,
        mode: props.mode ? props.mode : 'free',
        disabled: false,
        slides: {
          perView: props.itemsPerSlide ?? 'auto',
          spacing: props.itemsSpacing ?? 2,
        },
        initial: 0,
        slideChanged(slider) {
          setSlider(slider)
          setCurrentSlide(slider.track.details.rel)
          resetCarouselArrows(slider)
        },
        created(slider) {
          setSlider(slider)
          resetCarouselArrows(slider)
        },
        updated(slider) {
          setSlider(slider)
          resetCarouselArrows(slider)
        },
        optionsChanged(slider){
          setSlider(slider)
          resetCarouselArrows(slider)
        }
      })
    }, 10)
  }, [])

  const resetCarouselArrows = debounce((slider: KeenSliderInstance|null) => {
    if(slider?.track.details){
      setIsAtStart(slider.track.details.abs === 0)
      setIsAtFinish(slider.track.details.abs === slider.track.details.maxIdx)
      setLoaded(true)
    }
  }, 500);

  return (
    <div className="carousel-wrapper" onMouseEnter={() => {
        resetCarouselArrows(slider)
      }}>
        <div ref={sliderRef} className="keen-slider">
          {props.children}
        </div>
        {loaded && slider && (
          <>
            {!isAtStart &&
              <div className='shadow-mask shadow-mask-left'
                onClick={(e: any) => {
                  if(slider.track?.details)
                    return e.stopPropagation() || slider.prev()
                  }
                }>
                {
                  slider.track?.details &&
                  <Arrow
                    left
                    disabled={currentSlide === 0}
                  />
                }
              </div>
            }

            {!isAtFinish && Math.abs(slider.track?.details?.max) !== 0 &&
                <div className='shadow-mask shadow-mask-right'
                    onClick={(e: any) =>
                      {
                        if(slider.track?.details)
                          return e.stopPropagation() || slider.next()
                      }
                    }>
                    {
                      slider.track?.details &&
                      <Arrow
                        disabled={
                          currentSlide ===
                          slider.track?.details?.slides?.length - 1
                        }
                      />
                    }
                </div>
            }
          </>
        )}
    </div>
  )
}

export default CustomCarousel