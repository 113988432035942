import { Box, Tooltip } from '@mui/material';
import 'keen-slider/keen-slider.min.css';
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { MarketCategoryType } from "../../typing/types";
import AmplitudeHelper, { MarketSubCategoryClickOrigin } from "../../utils/AmplitudeHelper";
import CurrencyHelper from '../../utils/CurrencyHelper';
import IconHelper from "../../utils/IconHelper";
import { ROUTES_BASE } from "../../utils/consts";
import { useTypedSelector } from "../../utils/use-typed-selector";
import CustomCarousel from "./CustomCarousel";
import { LoadingAnimation } from './LoadingAnimation';


export const TopBarCategoryItem = (props: {marketCategory: MarketCategoryType, isUserInHome?: boolean}) => {

    const {marketCategory, isUserInHome} = props;
    const appTheme = useTypedSelector((state) => state.themeReducer.appTheme);
    // Total price of items in cart for this Category
    const categoryPrice = useTypedSelector((state) => {
        const products = state.eventReducer.currentEvent?.cart?.products.items ? state.eventReducer.currentEvent.cart.products.items : []
        return products.filter((cartItem) => cartItem.categories.some((category) => marketCategory.catalogItemCategoryUuids.includes(category.id))) // Only get items from this category
        .map((cartItem) => CurrencyHelper.convertCentimesToEuros(cartItem.exc_tax_total_price))
        .reduce((prev, next) => prev + next, 0)
    })

    // added this variable to set it through a useState and useEffect to avoid Hydration errors since the categoryPrice needs heavy calculations before updating
    const [_categoryPrice, set_CategoryPrice] = useState(0);
    
    const router = useRouter();
    const [show, setShow] = useState(false)
    const [currentCategory, setCurrentCategory] = useState<string>('');
    const [currentSubCategory, setCurrentSubCategory] = useState<string>('');

    useEffect(() => {
        if(!router.isReady) return;
 
        if(router.query.categoryId){
            setCurrentCategory(router.query.categoryId as string);
        }

        if(router.query.subCategoryId){
            setCurrentSubCategory(router.query.subCategoryId as string);
        }

    }, [router.isReady, router.query]);

    useEffect(() => {
        set_CategoryPrice(categoryPrice ?? 0);
    }, [categoryPrice])

    return (

        <>
            <Tooltip
                classes={{popper: `theme--${appTheme} topbar-category-item-tooltip`}}
                open={show}
                onOpen={() => setShow(true)}
                onClose={() => setShow(false)}
                title={
                    <Box boxShadow={10} className="topbar-category-subcategories-list-tooltip">
                        {
                            marketCategory.content?.marketShortSubCategories ? 
                                marketCategory.content.marketShortSubCategories.map((msc, index) => 
                                    <div key={index}>
                                        <Link href={`${ROUTES_BASE.CATALOGUE}/${marketCategory.slug}/${msc.slug}`}
                                            className={currentCategory === marketCategory.slug && currentSubCategory === msc.slug ? 'selected' : ''}
                                            onClick={() => {
                                                AmplitudeHelper.trackMarketSubCategoryClick(msc.slug, MarketSubCategoryClickOrigin.TOP_BAR_HOVER_ITEM)
                                                setShow(false)
                                            }}
                                        >
                                            <ReactSVG src={IconHelper.getMarketSubCategoryIconPath(msc.slug)}/> {msc.name}
                                        </Link>
                                    </div>
                                )
                                :
                                <LoadingAnimation/>
                        }
                    </Box>
                }
                componentsProps={{
                    tooltip: {
                        sx: {
                            color: '#000',
                            width: 'auto !important',
                            marginTop: '0 !important'
                        },
                    },
                }}
            >
                <div className={`carousel-cell topbar-category-item hoverable-bg clickable ${currentCategory === marketCategory.slug ? 'selected-category' : ''} ${_categoryPrice > 0 ? 'has-selected-items' : ''}`}>
                    <Link href={`${ROUTES_BASE.CATALOGUE}/${marketCategory.slug}`}
                          onClick={() => {
                              AmplitudeHelper.trackMarketSubCategoryClick(marketCategory.content.marketSubCategoriesSlug[0], MarketSubCategoryClickOrigin.TOP_BAR_MAIN_ITEM)
                          }}
                    >
                        <div className={`topbar-category-item`}>
                                <div className="topbar-category-item-left">
                                    <div className="topbar-category-item-image">
                                        <ReactSVG src={IconHelper.getMarketCategoryIconPath(marketCategory.slug)}/>
                                    </div>
                                </div>

                            <div className="topbar-category-item-right">
                                <p>{marketCategory.content.name}</p>
                                {/* <div className="highlighter"/> */}
                            </div>
                        </div>
                    </Link>

                    {!isUserInHome && 
                        <div className='price-container' onClick={() => {
                            // Moved this outside the Link above so that the price doesn't appear in the netlinking for SEO bots
                            AmplitudeHelper.trackMarketSubCategoryClick(marketCategory.content.marketSubCategoriesSlug[0], MarketSubCategoryClickOrigin.TOP_BAR_MAIN_ITEM)
                            router.push(`${ROUTES_BASE.CATALOGUE}/${marketCategory.slug}`)
                        }}>
                            <span>{_categoryPrice.toFixed(2)}€ HT</span>
                        </div>
                    }
                </div>
            </Tooltip>
        </>
    )
}

export const CategoriesTopBar = (props: {marketCategories: MarketCategoryType[]|null, isUserInHome?: boolean}) => {

    const {marketCategories, isUserInHome} = props;

    return (
        <div className={`topbar-categories-container full-categories main-borders-margin ${isUserInHome ? 'is-in-home' : ''}`}>
            <CustomCarousel>
                {marketCategories === null ?
                    <LoadingAnimation/>
                    :
                    marketCategories.map((marketCategory, index) => {
                    return <div className="keen-slider__slide" key={index}>
                        <TopBarCategoryItem marketCategory={marketCategory} isUserInHome={isUserInHome}/>
                    </div>
                })}
            </CustomCarousel>
        </div>
    )
};

