import { MarketingClient } from "../../../../proto_generated/marketing/MarketingServiceClientPb";
import { BlobGetMsg, BlobListMsg, BlobMsg } from "../../../../proto_generated/marketing/marketing_pb";
import { PageRequestMsg } from "../../../../proto_generated/type/pager_pb";
import { MarketSubCategoryType} from "../../../typing/types";
import JsonSchemaValidator, { JsonSchemaValidatorType } from "../../cms/JsonSchemaValidator";
import FilterMsg = BlobListMsg.FilterMsg;
import BlobKind = BlobMsg.BlobKind;

export default class MarketSubCategoryAPIHelper {

    //#region private stuff of the class
    static #ENV_URL = process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!;

    static #prepareMarketingClient = () => {
        return new MarketingClient(this.#ENV_URL);
    }
    //#endregion

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketSubCategoriesBySlugs = async (marketSubCategoriesSlugs: string[], withDecodingErrorCheck: boolean = true): Promise<MarketSubCategoryType[]> => {
        return new Promise<MarketSubCategoryType[]>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(999999)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.SUBCATEGORY])
            filterMsg.setSlugsList(marketSubCategoriesSlugs)
            blobListMsg.setFilter(filterMsg)

            this.#prepareMarketingClient().list(blobListMsg, {}, (err, res) => {
                if(res){
                    let marketSubCategories: MarketSubCategoryType[] = []
                    let errors: string[] = []

                    res.getBlobsList().forEach((blob) => {
                        let decodedContent = JSON.parse(blob.getContent())

                        // Check that JSON has the correct MarketSubCategory format
                        let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_SUB_CATEGORY, decodedContent) : undefined
                        if(decodingErrors){
                            errors.push(decodingErrors)
                        }else {
                            marketSubCategories.push({
                                uuid: blob.getUuid(),
                                slug: blob.getSlug(),
                                content: {
                                    name: decodedContent.name,
                                    title: decodedContent.title,
                                    description: decodedContent.description,
                                    descriptionV2_1: decodedContent.descriptionV2_1 ?? null,
                                    shortDescription: decodedContent.shortDescription!!,
                                    subCategoryH1: decodedContent.subCategoryH1,
                                    backEndCategoryUuid: decodedContent.backEndCategoryUuid,
                                    itemOrders: decodedContent.itemOrders,
                                    headTags: decodedContent.headTags
                                }
                            })
                        }
                    })
                    // If any decoding went wrong, reject
                    if(errors.length > 0){
                        reject(errors.join(', '))
                    }else {

                        // Sort sub categories according to the marketSubCategoriesSlugs given in parameters
                        // When fetching blobs, the back-end does retrieve them by when they were added to the db
                        // But the actual order we want to display is set into marketSubCategoriesSlugs in the Admin
                        marketSubCategories.sort((a, b) => {
                            const indexA = marketSubCategoriesSlugs.indexOf(a.slug);
                            const indexB = marketSubCategoriesSlugs.indexOf(b.slug);
                            // Handle cases where the name isn't in the orderList.
                            if (indexA === -1 && indexB === -1) {
                                return 0; // No change in order
                            }
                            if (indexA === -1) {
                                return 1; // Put items not in marketSubCategories at the end
                            }
                            if (indexB === -1) {
                                return -1; // Put items not in marketSubCategories at the end
                            }
                            return indexA - indexB;
                        });

                        resolve(marketSubCategories)
                    }
                }else{
                    console.error(err)
                    reject(err)
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketSubCategoryBySlug = async (marketSubCategorySlug: string, withDecodingErrorCheck: boolean = true): Promise<MarketSubCategoryType> => {
        return new Promise<MarketSubCategoryType>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(marketSubCategorySlug)

            this.#prepareMarketingClient().get(blobGetMsg, {}, (err, res) => {
                if(res){
                    let decodedContent = JSON.parse(res.getContent());
                    // Check that JSON has the correct MarketItem format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_SUB_CATEGORY, decodedContent) : undefined
                    if(decodingErrors){
                        reject(decodingErrors)
                    }else {
                        let marketSubCategory: MarketSubCategoryType = {
                            uuid: res.getUuid(),
                            slug: res.getSlug(),
                            content: {
                                name: decodedContent.name,
                                title: decodedContent.title,
                                description: decodedContent.description,
                                descriptionV2_1: decodedContent.descriptionV2_1 ?? null,
                                shortDescription: decodedContent.shortDescription!!,
                                subCategoryH1: decodedContent.subCategoryH1,
                                backEndCategoryUuid: decodedContent.backEndCategoryUuid,
                                itemOrders: decodedContent.itemOrders,
                                headTags: decodedContent.headTags
                            }
                        }
                        resolve(marketSubCategory)
                    }
                }else{
                    if(err.message === "marketing blob not found") {
                        reject("getMarketSubCategoryBySlug error: marketing blob not found")
                    }else{
                        reject("getMarketSubCategoryBySlug error:"+err.message)
                    }
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketSubCategoryNameBySlug = async (marketSubCategorySlug: string, withDecodingErrorCheck: boolean = true): Promise<string> => {
    
        return new Promise<string>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(marketSubCategorySlug)
    
            this.#prepareMarketingClient().get(blobGetMsg, {}, (err, res) => {
                if(res) {
                    let decoded = JSON.parse(res.getContent());
    
                    // Check that JSON has the correct MarketCategory format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_SUB_CATEGORY, decoded) : undefined
                    if(decodingErrors){
                        console.error('decoding error while getting market category name by slug! Error decoding JSON response')
                        reject('decoding error while getting market category name by slug! Error decoding JSON response')
                    }else{
                        resolve(decoded.name ?? marketSubCategorySlug)
                    }
                }else{
                    console.error(err.message);
                    reject('decoding error while getting market category name by slug! Error decoding JSON response')
                }
            })

        })
        
    }

}
