import { MarketingClient } from "../../../../proto_generated/marketing/MarketingServiceClientPb";
import { BlobGetMsg, BlobListMsg, BlobMsg } from "../../../../proto_generated/marketing/marketing_pb";
import { PageRequestMsg } from "../../../../proto_generated/type/pager_pb";
import { MarketCategoryType } from "../../../typing/types";
import JsonSchemaValidator, { JsonSchemaValidatorType } from "../../cms/JsonSchemaValidator";
import MarketSubCategoryAPIHelper from "./MarketSubCategoryAPIHelper";
import FilterMsg = BlobListMsg.FilterMsg;
import BlobKind = BlobMsg.BlobKind;
import ProductsAPIHelper from "../products/ProductsAPIHelper";
import MarketItemAPIHelper from "./MarketItemAPIHelper";

export default class MarketCategoryAPIHelper {

    //#region private stuff of the class
    static #ENV_URL = process.env.NEXT_PUBLIC_GRPC_WEB_PROXY_URL!!;

    static #prepareMarketingClient = () => {
        return new MarketingClient(this.#ENV_URL);
    }
    //#endregion

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getAllMarketCategories = async (withDecodingErrorCheck: boolean = true): Promise<MarketCategoryType[]> => {
        return new Promise<MarketCategoryType[]>((resolve, reject) => {
            let blobListMsg = new BlobListMsg()

            let pageReq = new PageRequestMsg()
            pageReq.setSorting("created")
            pageReq.setOrder("ASC")
            pageReq.setRequested(0)
            pageReq.setSize(999999)
            blobListMsg.setRequest(pageReq)

            let filterMsg = new FilterMsg()
            filterMsg.setKindsList([BlobKind.CATEGORY])
            blobListMsg.setFilter(filterMsg)

            // Get all MarketCategories
            this.#prepareMarketingClient().list(blobListMsg, {}, (err, res) => {
                if(res){
                    let marketCategories: MarketCategoryType[] = []
                    let errors: string[] = []

                    res.getBlobsList().forEach((blob) => {
                        let decodedContent = JSON.parse(blob.getContent())

                        // Check that JSON has the correct MarketCategory format
                        let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_CATEGORY, decodedContent) : withDecodingErrorCheck
                        if(decodingErrors){
                            errors.push(decodingErrors)
                        }else{
                            if(decodedContent.isPresentable) {
                                marketCategories.push({
                                    uuid: blob.getUuid(),
                                    slug: blob.getSlug(),
                                    content: {
                                        name: decodedContent.name!!,
                                        title: decodedContent.title!!,
                                        description: decodedContent.description!!,
                                        shortDescription: decodedContent.shortDescription!!,
                                        detailPageTitle: decodedContent.detailPageTitle!!,
                                        detailPageDescription: decodedContent.detailPageDescription!!,
                                        detailPageDescriptionV2_1: decodedContent.detailPageDescriptionV2_1!!,
                                        headTags: decodedContent.headTags!!,
                                        marketSubCategoriesSlug: decodedContent.marketSubCategoriesSlug!!,
                                        isPresentable: decodedContent.isPresentable,
                                        order: decodedContent.order ? decodedContent.order : 99
                                    },
                                    catalogItemCategoryUuids: []
                                })
                            }
                        }
                    })

                    // If any decoding went wrong, reject
                    if(errors.length > 0){
                        reject(errors.join(', '))
                    }else {
                        // Fetch all subcategories present in every marketSubCategoriesSlug
                        MarketSubCategoryAPIHelper
                            .getMarketSubCategoriesBySlugs(marketCategories.flatMap((marketCategory) => marketCategory.content.marketSubCategoriesSlug), withDecodingErrorCheck)
                            .then((marketSubCategories) => {
                                marketCategories.forEach((marketCategory) => {
                                    marketCategory.catalogItemCategoryUuids = marketSubCategories
                                        .filter((marketSubCategory) => marketCategory.content.marketSubCategoriesSlug.some(slug => marketSubCategory.slug === slug))
                                        .map(marketSubCategory => marketSubCategory.content.backEndCategoryUuid);
                                    
                                    let shortSubCategories: {slug: string, name: string}[] = [];
                                    marketCategory.content.marketSubCategoriesSlug.forEach(scSlug => {
                                        const sc = marketSubCategories.find(sc => sc.slug === scSlug)
                                        if(sc){
                                            shortSubCategories.push({
                                                slug: scSlug,
                                                name: sc.content.name
                                            })
                                        }
                                    })
                                    marketCategory.content.marketShortSubCategories = shortSubCategories
                                })

                                // Sort MarketCategory by their order
                                marketCategories.sort((a, b) => a.content.order - b.content.order);
                                resolve(marketCategories)

                            }).catch(err => {
                                reject(err)
                        })
                    }
                }else{
                    reject(err)
                }
            })
        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketCategoryBySlug = async (marketCategorySlug: string, withDecodingErrorCheck: boolean = true): Promise<MarketCategoryType> => {
        return new Promise<MarketCategoryType>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(marketCategorySlug)

            this.#prepareMarketingClient().get(blobGetMsg, {}, (err, res) => {
                if(res) {
                    let decoded = JSON.parse(res.getContent());

                    // Check that JSON has the correct MarketCategory format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_CATEGORY, decoded) : undefined
                    if(decodingErrors){
                        reject(decodingErrors)
                    }else{
                        MarketSubCategoryAPIHelper
                            .getMarketSubCategoriesBySlugs(decoded.marketSubCategoriesSlug, withDecodingErrorCheck)
                            .then((marketSubCategories) => {
                                resolve({
                                    uuid: res.getUuid(),
                                    slug: res.getSlug(),
                                    content: {
                                        name: decoded.name ?? '',
                                        title: decoded.title ?? '',
                                        description: decoded.description ?? '',
                                        shortDescription: decoded.shortDescription!!,
                                        detailPageTitle: decoded.detailPageTitle ?? '',
                                        detailPageDescription: decoded.detailPageDescription ?? '',
                                        detailPageDescriptionV2_1: decoded.detailPageDescriptionV2_1!!,
                                        headTags: decoded.headTags ?? [],
                                        marketSubCategoriesSlug: decoded.marketSubCategoriesSlug ?? [],
                                        isPresentable: decoded.isPresentable,
                                        order: decoded.order ? decoded.order : 99
                                    },
                                    catalogItemCategoryUuids: marketSubCategories.map(marketSubCategory => marketSubCategory.content.backEndCategoryUuid)
                                })
                            }).catch(err => {
                            reject(err)
                        })
                    }
                }else{
                    console.error(err)
                    if(err.message === "marketing blob not found") {
                        reject("getMarketCategoryBySlug error: marketing blob not found")
                    }else{
                        reject("getMarketCategoryBySlug error:"+err.message)
                    }
                }
            })

        })
    }

    /**
     * WARNING: Only set 'withDecodingErrorCheck' to 'false' for performance reasons and when you are certain that possible corrupted content is handled.
     * It is set to true by default, it is up to the caller to decide if it is justified to skip on the decoding part (e.g: the search feature is a valid reason
     * as we don't want to block the user when he's searching to rent an item + if there is a decoding error it will make a wrong re-direction and we'll have
     * a 404 error in our crash analytics).
     *
     * As a benchmark: checking decoding errors for 600 MarketItems takes around 8 seconds on a Pixel 7 Pro. Same-ish on an Iphone 11 Pro.
     */
    static getMarketCategoryNameBySlug = async (marketCategorySlug: string, withDecodingErrorCheck: boolean = true): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            let blobGetMsg = new BlobGetMsg()
            blobGetMsg.setSlug(marketCategorySlug)

            this.#prepareMarketingClient().get(blobGetMsg, {}, (err, res) => {
                if(res) {
                    let decoded = JSON.parse(res.getContent());

                    // Check that JSON has the correct MarketCategory format
                    let decodingErrors = withDecodingErrorCheck ? JsonSchemaValidator.getErrors(JsonSchemaValidatorType.MARKET_CATEGORY, decoded) : undefined
                    if(decodingErrors){
                        reject(decodingErrors)
                    }else{
                        MarketSubCategoryAPIHelper
                            .getMarketSubCategoriesBySlugs(decoded.marketSubCategoriesSlug, withDecodingErrorCheck)
                            .then((marketSubCategories) => {
                                resolve(decoded.name ?? '')
                            }).catch(err => {
                            reject(err)
                        })
                    }
                }else{
                    console.error(err)
                    if(err.message === "marketing blob not found") {
                        reject("getMarketCategoryNameBySlug error: marketing blob not found")
                    }else{
                        reject("getMarketCategoryNameBySlug error:"+err.message)
                    }
                }
            })

        })
    }


    /**
     * Logic goes:
     * - Get the catalog according to the spatial token
     * - Get the MarketItem for these CatalogItem
     * - Get the list of MarketCategory and MarketSubCategory that are present in this catalog in the MarketItems
     * - Fetch all MarketCategories and MarketSubCategories
     * - Filter these according to the list of MarketCategory and MarketSubCategory present in the catalog
     */
    static getMarketCategoriesForSpatialToken = async (spatialToken: string, withDecodingErrorCheck: boolean = true): Promise<MarketCategoryType[]> => {

        try {
            let catalogItems = await ProductsAPIHelper.listCatalog([], spatialToken)
            if (catalogItems.length === 0) {
                return []
            }
            const marketItems = await MarketItemAPIHelper.getMarketItemsByOwners(catalogItems.map(catalogItem => catalogItem.getUuid()), withDecodingErrorCheck)

            const availableMarketCategorySlugs: Set<string> = new Set();
            const availableMarketSubCategorySlugs: Set<string> = new Set();

            marketItems.forEach((item) => {
                availableMarketCategorySlugs.add(item.content.marketCategorySlug);
                availableMarketSubCategorySlugs.add(item.content.marketSubCategorySlug);
            });

            const marketCategories = await MarketCategoryAPIHelper.getAllMarketCategories(withDecodingErrorCheck)

            // Filter marketCategories that aren't present in the catalog
            let filteredMarketCategories = marketCategories.filter(marketCategory => availableMarketCategorySlugs.has(marketCategory.slug))

            // Filter marketSubCategories that aren't present in the catalog
            filteredMarketCategories.forEach(marketCategory => {
                marketCategory.content.marketSubCategoriesSlug = marketCategory.content.marketSubCategoriesSlug.filter((marketSubCategorySlug) => availableMarketSubCategorySlugs.has(marketSubCategorySlug))

                if (marketCategory.content.marketShortSubCategories) {
                    marketCategory.content.marketShortSubCategories = marketCategory.content.marketShortSubCategories.filter((marketShortSubCategory) => availableMarketSubCategorySlugs.has(marketShortSubCategory.slug))
                }
            })
            return filteredMarketCategories
        }catch (err) {
            throw err
        }
    }

}
